import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { MdArrowBack } from 'react-icons/md'

import styles from './Hero.module.css'

export const Box = ({ className, children }) => (
  <div className={cx(styles.box, className)}>
    <div className={styles.inner}>{children}</div>
  </div>
)

export const Back = ({ to = '/', className }) => (
  <Link to={to} className={cx(styles.back, className)}>
    <MdArrowBack className={styles.backIcon} /> Back
  </Link>
)

export const Title = ({ children, className }) => (
  <h1 className={cx(styles.title, className)}>{children}</h1>
)

export const Lead = ({ children, className }) => (
  <p className={cx(styles.lead, className)}>{children}</p>
)
