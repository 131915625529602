import React from 'react'
import { Link } from 'gatsby'
import { FaGithub } from 'react-icons/fa'
import digiaLogo from '../../assets/images/digia-horizontal.svg'

import styles from './Navbar.module.css'

const Navbar = () => (
  <div className={styles.component}>
    <div className={styles.leftColumn}>
      <Link to="/" className={styles.logoLink}>
        <img className={styles.logoImage} src={digiaLogo} alt="Digia" />
      </Link>
    </div>
    <div className={styles.middleColumn}>
      {/*<Link to="/join-us" className={styles.joinLink}>
        Would you like to join us?
      </Link>*/}
    </div>
    <div className={styles.rightColumn}>
      <a
        className={styles.githubLink}
        href="https://github.com/digiaonline"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <FaGithub className={styles.githubIcon} />
      </a>
    </div>
  </div>
)

export default Navbar
