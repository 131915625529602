import React from 'react'

import gatsbyLogo from '../../assets/images/gatsby-horizontal.svg'

import styles from './Gatsby.module.css'

const Gatsby = () => (
  <div className={styles.component}>
    Created with
    <a href="https://www.gatsbyjs.org/" target="_blank" rel="nofollow noopener noreferrer">
      <img className={styles.logo} src={gatsbyLogo} alt="Gatsby" />
    </a>
  </div>
)

export default Gatsby
