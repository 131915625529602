import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Navbar from '../components/Navbar'
import Gatsby from '../components/Gatsby'

import digiaLogo from '../../assets/images/digia-logo.png'

import '../layouts/index.css'

const Layout = ({ children }) => (
  <StaticQuery query={graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `} render={({ site: { siteMetadata } }) => (
    <div id="___root">
      <Helmet
        title={siteMetadata.title}
        meta={[
          { name: 'description', content: siteMetadata.description },
          { name: 'theme-color', content: '#ac0d16' },
          { property: 'og:title', content: siteMetadata.title },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: siteMetadata.siteUrl },
          { property: 'og:description', content: siteMetadata.description },
          { property: 'og:image', content: siteMetadata.siteUrl + digiaLogo },
          { property: 'og:image:width', content: '1200' },
          { property: 'og:image:height', content: '990' },
        ]}
        link={[
          { rel: 'sitemap', href: '/sitemap.xml' },
        ]}
      />
      <Navbar />
      {children}
      <Gatsby />
    </div>
  )}/>
)

export default Layout
